'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitContactsCtrl

 # @description

###
class UnitContactsCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UnitsManager
    $q
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @fields = UnitsManager.getContactsForm()

      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    add = ->
      @progress = true
      $q.when(UnitsManager.addContact(@id, angular.copy(@model)))
        .then =>
          @model = {}
          @load()

    remove = (contactId) ->
      @progress = true
      $q.when(UnitsManager.removeContact(@id, contactId))
        .then =>
          @load()

    load = ->
      UnitsManager.one(@id)
        .then (data) =>
          @unitLabel = data.label
          @items = _.filter data.unitContacts, (item) -> !item.deletedAt?
          @progress = false
          @form = {}


    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'


    init()
angular
  .module('mundoAdmin.units')
  .controller 'UnitContactsCtrl', UnitContactsCtrl
